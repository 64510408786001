export const API_URL_USER =
  "https://p1epwkejnb.execute-api.ap-south-1.amazonaws.com/dev";
export const API_URL_DEVICE =
  "https://stage.api01.rapidup.io/v1/device-service/";
// export const API_URL_DEVICE = "http://localhost:4001/v1/device-service/";

// "https://cfqpolwpj6.execute-api.ap-south-1.amazonaws.com/dev";

export const LOGIN = "/v1/auth/login";
export const REFRESH = "/v1/auth/refresh-tokens";
export const REGISTER = "/v1/auth/register";
export const FORGOT_PASSWORD = "/v1/auth/forgot-password";
export const RESET_PASSWORD = "/v1/auth/reset-password";
export const DEVICE = "groups/fetch-devices/";
export const UNGROUPED =
  "groups/ungrouped-devices?limit=1000&page=1&sortBy=-createdAt";
export const ADD_NEW_DEVICES_TO_GROUP = "groups/add-new-devices/";
export const REMOVE_DEVICES_FROM_GROUP = "groups/remove-devices/";
export const GROUPS = "groups";
export const DASHBOARD_DEVICES = "dashboards/monthly-device-aggregates";
export const USERS = "/v1/users";
export const DASHBOARD_FLEET = "dashboards/fleet-info";
export const DEVICE_DROPDOWN = "devices";
export const GROUP_DROPDOWN = "groups/list-group";
export const NOTIFICATIONS = "notifications";
export const NOTIFICATIONS_SEEN = "notifications/mark-seen";
export const NOTIFICATIONS_UNSEEN = "notifications/unseen-count";
export const PROCESS = "devices/process-details/";
export const KILL = "remote-command/kill?pid=";
export const INVOKE = "remote-command/invoke-command";
export const CHUNK_TYPE_PROCESS = "CHUNKED_LIVE_PROCESS_EVENT";
export const TYPE_PROCESS = "LIVE_PROCESS_EVENT";
export const TYPE_SERVICE = "LIVE_SYSTEMCTL_LIST";
export const TYPE_LOAD = "LIVE_CPU_LOAD";
export const TYPE_CONNECTION = "LIVE_NET_CONNECTIONS";
export const CHUNKED_TYPE_CONNECTION = "CHUNKED_LIVE_NET_CONNECTIONS";
export const TYPE_TRAFFIC = "LIVE_NET_TRANSFER";
export const REVOKE = "remote-command/revoke-command/";
export const SYSTEM_STATUS = "devices/device-overview/";
export const TYPE_KERNEL_LOGS = "LIVE_KERNAL_LOGS";
export const CHUNKED_KERNEL_LOGS = "CHUNKED_LIVE_KERNAL_LOG";
export const DEVICE_EDIT = "devices/fetch/";
export const PING = "remote-command/ping/";
export const API_KEY = "AIzaSyAGTurEqlQ9tXkGW8FHuhr1QVd9e9ICZkU";
export const LIST_UPGRADE_PLAN = "payments/plans";
export const ADD_CARD = "payments/add-card";
export const CREATE_SUBSCRIPTION = "payments/create-subscription";
export const SHOW_USER_SUBSCRIPTION = "payments/active-user-subscription";
export const CANCEL_SUBSCRIPTION = "payments/cancel-subscription/";
export const LIST_CARD = "payments/list-cards";
export const DELETE_CARD = "/payments/delete-card";
export const LIST_INVOICES = "payments/list-invoices";
export const USER_INVITE = "/v1/users/invite";
export const CHECK_USER_TAKEN = "/v1/users/check-email";
export const EDIT_USER = "/v1/users/";
export const USER_PERMISSION = "/v1/users/permissions/";
export const DEVICE_USAGE = "devices/usage";
export const DELETE_GROUP = "groups/fetch/";
export const ROLLOUTS = "rollouts/rt";
export const UPLOAD_ARTIFACTS = "agents/upload-artifact";
export const ROLLOUTS_DETAILS = "rollouts/rt/details";
export const ROLLOUT_DETAILED_STATUS = "rollouts/rt/detailed-stats";
export const ROLLOUT_LOGS = "rollouts/rt/logs/";
