import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../components/login";
import ForgotPassword from "../components/login/ForgetPassword";
import ResetPassword from "../components/login/ResetPassword";
import { LandingPage } from "./LandingPage";
import AuthRoute from "./AuthRoute";
import { pusher, prodPusher } from "../components/common/pusher";

const AppRoutes = () => {
  const [domainName, setDomainName] = useState("");
  useEffect(() => {
    setDomainName(window.location.host);
  }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="*" element={<AuthRoute />}>
            <Route
              path="*"
              element={
                <LandingPage
                  pusher={domainName === "app.rapidup.io" ? prodPusher : pusher}
                />
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
