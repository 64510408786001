import * as React from "react";
import { useEffect, useState } from "react";
import "./Logs/index.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Modal from "@mui/material/Modal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { TextField, Button } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@mui/material/Box";
import Add from "../resources/images/add.png";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import { invokeApi, invokeUploadFile } from "./api/index";
import {
  API_URL_DEVICE,
  DEVICE,
  UPLOAD_ARTIFACTS,
  ROLLOUTS,
  GROUP_DROPDOWN,
  ROLLOUT_DETAILED_STATUS,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";

import Select from "../components/common/Select";
import FileUpload from "./core/file-uploader";
import CreateButton from "./common/CreateButton";
import PercentLoader from "./core/loader/percentageLoader";
const containerStyles = {
  height: 20,
  width: "100%",
  backgroundColor: "#8fbac2ba",
  borderRadius: 50,
  display: "flex",
  position: "relative",
  overflow: "hidden",
};
const labelStyles = {
  padding: "1px",
  fontSize: "12px",
  color: "white",
  fontWeight: "bold",
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
};

const columns = [
  { id: "name", label: "Rollout name" },
  { id: "description", label: "Description" },
  { id: "status", label: "Details status" },
  { id: "totalDevices", label: "Total devices" },
  { id: "createdBy", label: "Created by" },
  { id: "createdAt", label: "Created date" },
  { id: "actions", label: "Actions" },
];
const deviceColumns = [
  { id: "controllerId", label: "Controller Id" },
  { id: "deviceName", label: "Device Name" },
  { id: "description", label: "Description" },
  { id: "status", label: "Status" },
  { id: "createdBy", label: "Created By" },
  { id: "createdAt", label: "Created Date" },
  { id: "updatedBy", label: "Modified By" },
  { id: "updatedAt", label: "Modified Date" },
  { id: "actions", label: "Actions", align: "center" },
];
const MoreActionOptions = ({
  id,
  cloneRollout,
  editId,
  isEditModal,
  delModal,
  showEditIcon,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex" }}>
      <MoreHorizOutlinedIcon
        style={{
          color: "#075a6a",
          cursor: "pointer",
        }}
        id="resources-button"
        fontSize="large"
        onClick={handleClick}
        aria-controls={open ? "resources-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />

      <Menu
        id="resources-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{ "aria-labelledby": "resources-button" }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            cloneRollout(id);
            handleClose();
          }}
        >
          <div style={{ display: "flex" }}>
            <FileCopyIcon
              style={{
                color: "#28d1d5",
                cursor: "pointer",
              }}
            />
            <span> Copy</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            editId(id);
            isEditModal(true);
            handleClose();
          }}
          disabled={showEditIcon}
        >
          <div style={{ display: "flex" }}>
            <EditIcon
              style={{
                color: "#075a6a",
                cursor: "pointer",
                height: "25px",
                width: "20px",
              }}
            />
            <span>Edit</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            delModal((prevVal) => ({
              ...prevVal,
              status: true,
              id: id,
            }));
            handleClose();
          }}
        >
          <div style={{ display: "flex" }}>
            <DeleteOutlineIcon style={{ cursor: "pointer" }} color="error" />
            <span>Delete</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};
const RollOut = (props) => {
  const history = useNavigate();
  const [groupList, setGroupList] = useState([]);
  const [modal, setModal] = useState(false);
  const [rollout, setRollout] = useState([]);
  const [rolloutPage, setRolloutPage] = useState(0);
  const [rolloutBuffer, setRolloutBuffer] = useState([]);
  const [rolloutRefresh, setRolloutRefresh] = useState(0);
  const [devicesList, setDevicesList] = useState([]);
  const [rolloutTotalList, setRolloutTotalList] = useState(0);
  const [checks, setChecks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [delModal, setDelModal] = useState({ status: false, id: null });
  const [editId, setEditId] = useState("");
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  const [rolloutList, setRolloutList] = useState([]);
  const [fileUploadData, setFileUploadData] = useState(null);
  const [aritifactName, setAritifactName] = useState("");
  const [isFileUploading,setIsFileUploading]=useState(false)
  const [loadPercentage, setLoadPercentage] = useState(-1);
  const [showDetailedModal, setShowDetailedModal] = useState(false);
  const [detailedStatus, setDetailedStatus] = useState({
    applied: 0,
    completion: 0,
    downloaded: 0,
    extracted: 0,
  });
  const [addRolloutData, setAddRolloutData] = useState({
    name: "",
    description: "",
    group: null,
    type: "",
    artifact: "",
    deviceIds: checks,
  });
  const [pusherData, setPusherData] = useState(null);
  useEffect(() => {
    fetchRollouts(rolloutPage + 1);
  }, [rolloutRefresh]);
  useEffect(()=>{
    if(aritifactName!==""){
      setIsFileUploading(false)
    }
  },[aritifactName])


  useEffect(() => {
    if (rollout.length && pusherData !== null) {
      setRollout((prevState) => {
        let newData = prevState.filter((ele) => ele.id !== pusherData.id);
        newData = [
          ...newData,
          createData(
            pusherData.id,
            pusherData.name,
            pusherData.completion,
            pusherData.description,
            pusherData.status,
            `${pusherData.completedDevices.length}/${pusherData.deviceIds.length}`,
            pusherData.createdBy ? pusherData.createdBy.name : "",
            pusherData.createdAt
              ? moment(pusherData.createdAt)
                  .local()
                  .format("DD MMM YYYY (hh:mm A)")
              : ""
          ),
        ];

        return newData;
      });
    }
  }, [pusherData]);

  useEffect(() => {
    const id = user.parent === null ? user.id : user.parent;
    let channel = props.pusher.subscribe(id);
    channel.bind("ROLLOUT_STATUS_UPDATE", (data) => {
      setPusherData(data.rollout);
    });
    return () => {
      props.pusher.unsubscribe(id);
    };
  }, []);
  useEffect(() => {
    props.headerName("OTA Update");
  }, []);
  useEffect(() => {
    setAddRolloutData((prevVal) => ({ ...prevVal, deviceIds: checks }));
  }, [checks]);
  //update the value of checkbox and action button whenever refresh state changes
  useEffect(() => {
    setDevicesList((prevVal) =>
      devicesList.map((device) => {
        return {
          ...device,
          checkBox: (
            <Checkbox
              checked={checks.includes(device.deviceId)}
              onChange={() => {
                checkFun(device.deviceId);
              }}
            />
          ),
          actions: (
            <div
              onClick={() => {
                checkFun(device.deviceId);
              }}
              style={{
                backgroundColor: checks.includes(device.deviceId)
                  ? "#e56767"
                  : "#075a6a",
                borderRadius: "20px",
                padding: "5px",
                color: "white",
                cursor: "pointer",
                width: "max-content",
              }}
            >
              <AddIcon
                style={{
                  fontSize: "20px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  marginRight: "5px",
                  color: "#0fcbd0",
                  position: "relative",
                  top: "2px",
                }}
              />
              <span
                style={{
                  position: "relative",
                  top: "-5px",
                  fontSize: "12px",
                }}
              >
                Add Device
              </span>
            </div>
          ),
        };
      })
    );
  }, [refresh]);
  useEffect(() => {
    if (editId !== "") {
      let d = rolloutList.find((i) => i.id == editId);
      let fileName;
      if (d.artifact) {
        fileName = new URL(d.artifact).pathname.substring(1);
      }

      setAddRolloutData((prevVal) => ({
        ...prevVal,
        name: d.name,
        description: d.description,
        type: d.type,
        group: d.group ? d.group.id : null,
        deviceIds: d.group ? d.deviceIds : [],
        artifact: d.artifact ? d.artifact : "",
      }));
      setAritifactName(fileName);
    }
  }, [editId]);

  useEffect(() => {
    if (addRolloutData.deviceIds !== []) {
      setChecks(addRolloutData.deviceIds);
    }
  }, [addRolloutData.deviceIds]);
  const showDetailedStatus = (id) => {
    invokeApi("get", `${API_URL_DEVICE}${ROLLOUT_DETAILED_STATUS}/${id}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        setDetailedStatus(res);
      })
      .catch((err) => {
        toast.error(err, { autoClose: 2000 });
      });
  };
  const createData = (
    id,
    RolloutName,
    completion,
    description,
    detailStatus,
    totalDevices,
    createdBy,
    createdAt
  ) => {
    let name = (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          history(`/rollout-details/${id}`);
        }}
      >
        {RolloutName}
      </div>
    );
    let actions = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {detailStatus === "CREATED" ? (
          <PlayArrowIcon
            fontSize="large"
            style={{
              color: "#075a6a",
              cursor: "pointer",
            }}
            onClick={() => {
              playRollout(id);
            }}
          />
        ) : detailStatus === "FAILED" ? (
          <RefreshIcon
            fontSize="large"
            style={{
              color: "#28d1d5",
              cursor: "pointer",
            }}
            onClick={() => {
              playRollout(id);
            }}
          />
        ) : (
          <Tooltip title="Retry not allowed">
            <RefreshIcon
              style={{
                color: "#a7afaf",
              }}
              fontSize="large"
              disabled
            />
          </Tooltip>
        )}

        <div>
          <MoreActionOptions
            id={id}
            cloneRollout={cloneRollout}
            editId={setEditId}
            isEditModal={setModal}
            delModal={setDelModal}
            showEditIcon={
              detailStatus === "CREATED" || detailStatus === "FAILED"
                ? false
                : true
            }
          />
        </div>
      </div>
    );
    let status = (
      <div
        onClick={() => {
          setShowDetailedModal(true);
          showDetailedStatus(id);
        }}
      >
        <div style={containerStyles}>
          <div
            style={{
              height: "100%",
              width: `${completion}%`,
              backgroundColor: detailStatus === "COMPLETED" ? "#0fcbd0" : "red",
              borderRadius: "inherit",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <div style={labelStyles}>
              {detailStatus === "COMPLETED"
                ? `${completion}%`
                : ` ${completion}%`}
            </div>
          </div>
        </div>
      </div>
    );
    return {
      id,
      name,
      description,
      status,
      totalDevices,
      createdBy,
      createdAt,
      actions,
    };
  };
  const detailedModal = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span>Status Details</span>

          <span
            onClick={() => {
              setShowDetailedModal(false);
              setDetailedStatus({
                applied: 0,
                completion: 0,
                downloaded: 0,
                extracted: 0,
              });
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div>
          <div>
            <Grid
              md={12}
              xs={12}
              item
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                md={3}
                sm={6}
                xs={8}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <p style={{ marginBottom: "0px" }}> Applied</p>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p style={{ marginBottom: "0px" }}> {detailedStatus.applied}</p>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                md={3}
                sm={6}
                xs={8}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <p style={{ marginBottom: "0px" }}> Completion</p>
              </Grid>
              <Grid
                item
                md={3}
                xs={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p style={{ marginBottom: "0px" }}>
                  {detailedStatus.completion}%
                </p>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                md={3}
                sm={6}
                xs={8}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <p style={{ marginBottom: "0px" }}> Downloaded</p>
              </Grid>
              <Grid
                item
                md={3}
                xs={5}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p style={{ marginBottom: "0px" }}>
                  {" "}
                  {detailedStatus.downloaded}
                </p>
              </Grid>
            </Grid>
            <Grid
              md={12}
              xs={12}
              item
              container
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                md={3}
                sm={6}
                xs={8}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <p style={{ marginBottom: "0px" }}> Extracted</p>
              </Grid>
              <Grid
                item
                md={3}
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p style={{ marginBottom: "0px" }}>
                  {" "}
                  {detailedStatus.extracted}
                </p>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0px 20px 0px",
            }}
          >
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              onClick={() => {
                setShowDetailedModal(false);
                setDetailedStatus({
                  applied: 0,
                  completion: 0,
                  downloaded: 0,
                  extracted: 0,
                });
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const detailedModalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={showDetailedModal === true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setShowDetailedModal(false);
            setDetailedStatus({
              applied: 0,
              completion: 0,
              downloaded: 0,
              extracted: 0,
            });
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, width: "30%" }}>
          {detailedModal()}
        </Box>
      </Modal>
    );
  };
  const deleteRolloutModal = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span style={{ paddingLeft: "40%" }}>Are You Sure ?</span>

          <span
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              deleteRollout(delModal.id);
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const deleteFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={delModal.status === true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setDelModal((prevVal) => ({
              ...prevVal,
              status: false,
              id: null,
            }));
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "550px" }}>
          {deleteRolloutModal()}
        </Box>
      </Modal>
    );
  };
  const deleteRollout = (rollId) => {
    invokeApi("DELETE", `${API_URL_DEVICE}${ROLLOUTS}/d/${rollId}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        setDelModal((prevVal) => ({ ...prevVal, status: false, id: null }));
        setRolloutRefresh(Math.random());
        setRolloutBuffer([]);
        setRolloutPage(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to delete device", { autoClose: 2000 });
      });
  };
  const fetchRollouts = (pg) => {
    let params = { page: pg,sortBy:'-createdAt' };

    invokeApi("GET", `${API_URL_DEVICE}${ROLLOUTS}`, null, params)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        setRolloutList(res.results);
        const rollouts = res.results.map((iter) => {
          return createData(
            iter.id,
            iter.name,
            iter.completion,
            iter.description,
            iter.status,
            `${iter.completedDevices.length}/${iter.deviceIds.length}`,
            iter.createdBy ? iter.createdBy.name : "",
            iter.createdAt
              ? moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)")
              : ""
          );
        });
        let array = rolloutBuffer;
        array.push(pg);
        setRolloutBuffer(array);

        setRolloutTotalList(res.totalResults);

        if (pg == 1) {
          setRollout(rollouts);
        } else {
          setRollout([...rollout, ...rollouts]);
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 2000 });
      });
  };
  useEffect(() => {
    fetchGroupList();
  }, []);
  // invoke api to fetch the devices when we select the grp
  useEffect(() => {
    if(addRolloutData.group){
      invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + addRolloutData.group)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        let devicedata = res.map((iter, pos) => {
          return deviceTabelCreateData(
            iter.id,
            pos,
            iter.controllerId,
            iter.deviceName,
            iter.description,
            iter.status,
            iter.createdBy,
            iter.createdAt
              ? moment(iter.createdAt).local().format("DD MMM YYYY (hh:mm A)")
              : "",
            iter.updatedBy,
            iter.updatedAt
              ? moment(iter.updatedAt).local().format("DD MMM YYYY (hh:mm A)")
              : "",
            checks.includes(iter.id)
          );
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log(err);
      });
    }
 
  }, [addRolloutData.group, checks]);
  // we are not setting any boolean value while selecting the checkbox.so we're calling
  // this function whenever onchange calls.

  //so we are checking if checks state include that deviceId .
  //if true , then we remove that device from array. i.e; unchecked
  //if false , then we add that device id to array. i.e; checked

  const checkFun = (deviceId) => {
    if (checks.includes(deviceId)) {
      let c = checks;
      c.splice(c.indexOf(deviceId), 1);
      setChecks(c);
      setRefresh(Math.random());
    } else {
      let c = checks;
      c.push(deviceId);
      setChecks(c);
      setRefresh(Math.random());
    }
  };

  //this func return the data that we need to show in our table
  const deviceTabelCreateData = (
    deviceId,
    id,
    controllerId,
    deviceName,
    description,
    status,
    createdBy,
    createdAt,
    updatedBy,
    updatedAt,
    bool
  ) => {
    let checkBox = (
      <Checkbox
        checked={bool}
        onChange={() => {
          checkFun(deviceId);
        }}
      />
    );
    let actions = (
      <div
        onClick={() => {
          checkFun(deviceId);
        }}
        style={{
          backgroundColor: bool ? "#e56767" : "#075a6a",
          borderRadius: "20px",
          padding: "5px",
          color: "white",
          cursor: "pointer",
          width: "max-content",
        }}
      >
        <>
          <AddIcon
            style={{
              fontSize: "20px",
              backgroundColor: "white",
              borderRadius: "10px",
              color: "#0fcbd0",
              position: "relative",
              marginRight: "5px",
              top: "2px",
            }}
          />
          <span
            style={{
              position: "relative",
              top: "-5px",
              fontSize: "12px",
            }}
          >
            Add Device
          </span>
        </>
      </div>
    );

    return {
      id,
      checkBox,
      controllerId,
      deviceName,
      description,
      status,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      deviceId,
      actions,
    };
  };
  // fetch the list of groups to show in dropdown
  const fetchGroupList = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        }
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };
  //when go to onPage to other
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //select all checkbox
  const selectAll = (e) => {
    let array = [];
    if (e) {
      array = devicesList.map((iter) => {
        return iter.deviceId;
      });
      setChecks(array);
      setRefresh(Math.random());
    } else {
      setChecks([]);
      setRefresh(Math.random());
    }
  };
  //play rollout api call
  const playRollout = (id) => {
    invokeApi("POST", `${API_URL_DEVICE}${ROLLOUTS}/play/${id}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
        setRolloutPage(0);
        setRolloutRefresh(Math.random());
      })
      .catch((err) => {
        toast.error(err, { autoClose: 2000 });
      });
  };
  //clone rollout api call
  const cloneRollout = (id) => {
    invokeApi("POST", `${API_URL_DEVICE}${ROLLOUTS}/clone/${id}`)
      .then((res) => {
        if (!res.message) {
          toast.success("Copied successfully", { autoClose: 2000 });
          setRolloutPage(0);
          setRolloutRefresh(Math.random());
        }
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        }
      })
      .catch((err) => {
        toast.error(err, { autoClose: 2000 });
      });
  };
  useEffect(() => {
    const formData = new FormData();
    if (fileUploadData === null) {
      setAddRolloutData((prevVal) => ({ ...prevVal, artifact: null }));
      setAritifactName("");
    }
    if (fileUploadData?.size) {
      formData.append("upload", fileUploadData);
      invokeUploadFile(`${API_URL_DEVICE}${UPLOAD_ARTIFACTS}`, formData,setLoadPercentage)
        .then((res) => {
          setAddRolloutData((prevVal) => ({
            ...prevVal,
            artifact: res.data.location,
          }));
          setAritifactName(res.data.key);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [fileUploadData]);
  //edit rollout api call
  const editRollout = () => {
    let payload = {};

    for (let data in addRolloutData) {
      payload[data] = addRolloutData[data];
    }

    invokeApi("PUT", `${API_URL_DEVICE}${ROLLOUTS}/m/${editId}`, payload)
      .then((res) => {
        if (res.message) {
          toast.message(res.message, { autoClose: 2000 });
        } else {
          toast.success("Rollout Updated successfully", { autoClose: 2000 });
          setModal(false);
          setAddRolloutData((prevVal) => ({
            ...prevVal,
            name: "",
            description: "",
            artifact: "",
            group: null,
            type: "",
          }));
          setChecks([]);
          setEditId("");
          setFileUploadData(null);
          setRolloutPage(0);
          setRolloutBuffer([]);
          setAritifactName("");
          setRolloutRefresh(Math.random());
        }
      })
      .catch((err) => {
        toast.error("Failed to update rollout", { autoClose: 2000 });
      });
  };
  // create rollout api call
  const createRollout = () => {
    if (addRolloutData.name === "") {
      toast.error("RolloutName is required", { autoClose: 2000 });
    } else {
      invokeApi("POST", `${API_URL_DEVICE}${ROLLOUTS}`, {
        name: addRolloutData.name,
        type: addRolloutData.type,
        description: addRolloutData.description,
        group: addRolloutData.group,
        deviceIds: addRolloutData.deviceIds,
        artifact: addRolloutData.artifact,
      })
        .then((res) => {
          if (res) {
            if (res.message) {
              toast.error(res.message, { autoClose: 2000 });
            } else {
              toast.success("Rollout created successfully", {
                autoClose: 2000,
              });
              setModal(false);
              setAddRolloutData((prevVal) => ({
                ...prevVal,
                name: "",
                description: "",
                artifact: "",
                group: null,
                type: "",
              }));
              setChecks([]);
              setFileUploadData(null);
              setRolloutPage(0);
              setRolloutBuffer([]);
              setAritifactName("");
              setRolloutRefresh(Math.random());
            }
          }
        })
        .catch((err) => {
          toast.error(err, { autoClose: 2000 });
        });
    }
  };

  // add rollout modal
  const modalContent = () => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
            zIndex:10
          }}
        >
          <span>{editId !== "" ? "Edit Rollout" : "Add Rollout"}</span>
          <span
            onClick={() => {
              setModal(false);
              setAddRolloutData((prevVal) => ({
                ...prevVal,
                name: "",
                description: "",
                artifact: "",
                group: null,
                type: "",
              }));
              setChecks([]);
              setFileUploadData(null);
              setEditId("");
              setAritifactName("");
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div className="modal-box">
          <div>
            <Grid
              container
              item
              spacing={1}
              style={{
                width: "100%",
                margin: "0px",
                padding: "5px 30px 20px 30px",
              }}
              md={12}
              xs={12}
            >
              <Grid item xs={12} md={12} style={{ display: "flex" }}>
                <Grid item xs={12} md={12}>
                {loadPercentage>-1&&<PercentLoader value={loadPercentage}/>}
                  <Grid item xs={8} md={8}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h4
                        style={{
                          marginRight: 10,
                          marginBottom: "0.5em",
                        }}
                      >
                        Rollout name
                      </h4>
                      <TextField
                        name="name"
                        className="createGroupFields"
                        onChange={(e) => {
                          setAddRolloutData({
                            ...addRolloutData,
                            name: e.target.value,
                          });
                        }}
                        placeholder="Name"
                        value={addRolloutData?.name}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h4
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          marginBottom: "0.5em",
                        }}
                      >
                        Upload Artifacts
                      </h4>
                      <div >
                      <FileUpload
                        title="upload artifacts"
                        clsName="createGroupFields"
                        value={ aritifactName}
                        setIsFileUploading={setIsFileUploading}
                        callBack={setFileUploadData}
                      />
                      </div>
                    </div>
                  </Grid>
                  <Grid />
                  <Grid item xs={8} md={8}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "2em",
                      }}
                    >
                      <h4 style={{ marginRight: 10, marginBottom: "0.5em" }}>
                        Select group
                      </h4>
                      <Select
                        list={groupList}
                        name={"Group"}
                        value={addRolloutData?.group}
                        style={{
                          margin: "0px",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "7px",
                          color: " #0A4F6F",
                        }}
                        onChange={(e) => {
                          setChecks([]);
                          setAddRolloutData({ ...addRolloutData, group: e });
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <h4 style={{ marginRight: 10, marginBottom: "0.5em" }}>
                        Description
                      </h4>
                      <TextField
                        name="description"
                        className="createGroupFields"
                        onChange={(e) => {
                          setAddRolloutData({
                            ...addRolloutData,
                            description: e.target.value,
                          });
                        }}
                        color="primary"
                        value={addRolloutData?.description}
                        variant="standard"
                        multiline
                        rows={6}
                        InputProps={{
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12} style={{ marginBottom: 10 }}>
                    <div>
                      <h4 style={{ marginRight: 10, marginBottom: "0.5em" }}>
                        Update type
                      </h4>
                      <input
                        type="radio"
                        id="os"
                        name="type"
                        value="OS"
                        checked={addRolloutData.type === "OS" ? true : false}
                        onChange={(e) => {
                          setAddRolloutData((prevVal) => ({
                            ...prevVal,
                            type: e.target.value,
                          }));
                        }}
                      />
                      <label for="os" style={{ fontWeight: "normal" }}>
                        OS
                      </label>

                      <input
                        type="radio"
                        id="application"
                        name="type"
                        value="Application"
                        checked={
                          addRolloutData.type === "Application" ? true : false
                        }
                        onChange={(e) => {
                          setAddRolloutData((prevVal) => ({
                            ...prevVal,
                            type: e.target.value,
                          }));
                        }}
                      />
                      <label for="application" style={{ fontWeight: "normal" }}>
                        Application
                      </label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "auto",
                    backgroundColor: "transparent !important",
                    boxShadow: "none",
                  }}
                >
                  <TableContainer className="table-body">
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 10px",
                        marginTop: "-10px",
                      }}
                    >
                      <TableHead aria-label="sticky table">
                        <TableRow key="head">
                          <TableCell
                            key={"check"}
                            align="center"
                            style={{
                              color: "#085B74",
                              backgroundColor: "white",
                            }}
                          >
                            <Checkbox
                              onChange={(e) => {
                                selectAll(e.target.checked);
                              }}
                            />
                          </TableCell>
                          {deviceColumns.map((col) => (
                            <TableCell
                              key={col.id}
                              align="center"
                              style={{
                                color: "#085B74 ",
                                fontWeight: "bold",
                                alignItems: "center",
                              }}
                            >
                              {col.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {devicesList.length > 0 ? (
                          devicesList
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, pos) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  <TableCell
                                    key={"check" + pos}
                                    style={{
                                      color: "#085B74",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {row.checkBox}
                                  </TableCell>
                                  {deviceColumns.map((col) => {
                                    const value = row[col.id];
                                    return (
                                      <TableCell
                                        key={col.id}
                                        align="center"
                                        style={{
                                          color: "#085B74",
                                          backgroundColor: "white",
                                        }}
                                      >
                                        {col.format && typeof value === "number"
                                          ? col.format(value)
                                          : value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                        ) : (
                          <TableCell
                            colSpan={10}
                            align="center"
                            style={{
                              color: "#085B74",
                            }}
                          >
                            No records found
                          </TableCell>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    style={{ backgroundColor: "white", color: "#085B74" }}
                    component="div"
                    count={devicesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
          <hr style={{ margin: "0px 25px 25px" }} />
          <div className="footer">
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#0fcbd0",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              disabled={isFileUploading}
              onClick={editId !== "" ? editRollout : createRollout}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "10px",
                margin: "10px",
              }}
              disabled={isFileUploading}
              onClick={() => {
                setModal(false);
                setAddRolloutData((prevVal) => ({
                  ...prevVal,
                  name: "",
                  description: "",
                  artifact: "",
                  group: null,
                  type: "",
                }));
                setChecks([]);
                setFileUploadData(null);
                setEditId("");
                setAritifactName("");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </>
    );
  };

  // add rollout modal function
  const modalFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={modal ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setModal(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="createGroup"
          style={{
            p: 4,
            backgroundColor: "#E3ECF2",
            // width: "fit-content",
            color: "#085B74",
            maxWidth: "80%",
            minWidth: "40%",
            width: "max-content",
            maxHeight: "90vh",
          }}
        >
          {modalContent()}
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <div style={{ padding: " 20px",height:"85vh",overflow:"auto" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CreateButton
            title="Create Rollout"
            onClickFunc={() => setModal(true)}
          />
        </div>
        <Paper
          sx={{
            width: "100%",
            // overflow: "hidden",
            backgroundColor: "transparent !important",
            boxShadow: "none",
          }}
        >
          <TableContainer className="table-body">
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                marginTop: "-10px",
              }}
            >
              <TableHead aria-label="sticky table">
                <TableRow key="head">
                  {columns.map((col) => (
                    <TableCell
                      key={col.id}
                      align="center"
                      style={{
                        color: "#085B74 ",
                        fontWeight: "bold",
                        alignItems: "center",
                      }}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rollout
                  .slice(rolloutPage * 10, rolloutPage * 10 + 10)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((col, index) => {
                          const value = row[col.id];
                          return (
                            <TableCell
                              key={index}
                              align="center"
                              style={{
                                color: "#085B74",
                                backgroundColor: "white",
                              }}
                            >
                              {col.format && typeof value === "number"
                                ? col.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            style={{ backgroundColor: "white", color: "#085B74" }}
            count={rolloutTotalList}
            rowsPerPage={10}
            page={rolloutPage}
            onPageChange={(event, newPage) => {
              setRolloutPage(newPage);
              setRolloutRefresh(Math.random());
            }}
          />
        </Paper>
      </div>
      {modal && modalFun()}
      {deleteFun()}
      {showDetailedModal && detailedModalFun()}

    </div>
  );
};

export default RollOut;
