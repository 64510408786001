import React, { useState, useEffect } from "react";
import "./index.scss";
import { Button, TextField } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import Down from "../../resources/images/down.png";
import Up from "../../resources/images/up.png";
import { ROLLOUT_LOGS, API_URL_DEVICE } from "../api/constant";
import { invokeApi } from "../api";
import { toast } from "react-toastify";
import moment from "moment";

const TimeLogs = (props) => {
  const { id, rollId } = useParams();
  const [logs, setLogs] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [showDetails, setShowDetails] = useState({
    index: null,
    isOpen: false,
  });
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = logs.filter((item) => {
        return item.message.toLowerCase().includes(searchInput.toLowerCase());
      });

      setFilteredResults(filteredData);
    } else {
      setFilteredResults(logs);
    }
  };

  useEffect(() => {
    props.headerName("Time Log");
  }, []);
  useEffect(() => {
    getLogs();
  }, []);
  const getLogs = () => {
    invokeApi("get", `${API_URL_DEVICE}${ROLLOUT_LOGS}${id}/${rollId}`)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setLogs(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const moreDetails = (content) => {
    return (
      <div className="more-detail">
        <p>{content}</p>
      </div>
    );
  };
  return (
    <div className="main-container ">
      <Grid container xs={12} md={12} className="search-container">
        <Grid item sm={8} xs={12} md={10}>
          <TextField
            className="search"
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => searchItems(e.target.value)}
            placeholder="Search"
          ></TextField>
        </Grid>
        <Grid item sm={4} xs={6} md={2}>
          <div className="btn-container">
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#075a6a",
                color: "white",
                borderRadius: "18px",
                width: "80%",
              }}
              variant="contained"
              onClick={() => {
                searchItems(searchInput);
              }}
            >
              Search
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid conatiner md={12} xs={12}>
        <Grid item md={12} xs={12} className="table-container table-header">
          <Grid item md={5} xs={12} className="column">
            <Grid item md={6} xs={12} classname="message-wrapper">
              <p>Timestamp</p>
            </Grid>
          </Grid>
          <Grid item md={7} xs={12} className="column">
            <Grid item md={8} xs={12}>
              <p>Message</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {searchInput.length > 1 ? (
        <Grid conatiner md={12} xs={12} className="table-body">
          {filteredResults.map((data, index) => {
            return (
              <div style={{ margin: "10px 0px" }} key={index}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  className="table-container table-header"
                >
                  <Grid item md={5} xs={12} className="column body-content">
                    <Grid item md={8} xs={12} classname="message-wrapper">
                      <p>
                        {data.createdAt
                          ? moment(data.createdAt)
                              .local()
                              .format("DD MMM YYYY (hh:mm A)")
                          : ""}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className="column body-content message-wrapper"
                  >
                    <Grid item md={12} xs={12}>
                      <p>{data.message}</p>
                    </Grid>
                  </Grid>
                  <Grid item md={1} xs={2} className="column body-content">
                    {showDetails.isOpen && showDetails.index === index ? (
                      <img
                        src={Up}
                        alt="^"
                        className="img"
                        onClick={() => {
                          setShowDetails((preValue) => ({
                            ...preValue,
                            index,
                            isOpen:
                              index === preValue.index
                                ? !preValue.isOpen
                                : true,
                          }));
                        }}
                      />
                    ) : (
                      <img
                        src={Down}
                        alt="^"
                        className="img"
                        onClick={() => {
                          setShowDetails((preValue) => ({
                            ...preValue,
                            index,
                            isOpen:
                              index === preValue.index
                                ? !preValue.isOpen
                                : true,
                          }));
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                {showDetails.index === index &&
                  showDetails.isOpen &&
                  moreDetails(data.more)}
              </div>
            );
          })}
        </Grid>
      ) : (
        <Grid conatiner md={12} xs={12} className="table-body">
          {logs.map((data, index) => {
            return (
              <div style={{ margin: "10px 0px" }} key={index}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  className="table-container table-header "
                >
                  <Grid item md={5} xs={12} className="column body-content">
                    <Grid item md={8} xs={12} classname="message-wrapper">
                      <p>
                        {data.createdAt
                          ? moment(data.createdAt)
                              .local()
                              .format("DD MMM YYYY (hh:mm A)")
                          : ""}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    className="column body-content message-wrapper"
                  >
                    <Grid item md={12} xs={12}>
                      <p>{data.message}</p>
                    </Grid>
                  </Grid>
                  <Grid item md={1} xs={2} className="column body-content">
                    {showDetails.isOpen && showDetails.index === index ? (
                      <img
                        src={Up}
                        alt="^"
                        className="img"
                        onClick={() => {
                          setShowDetails((preValue) => ({
                            ...preValue,
                            index,
                            isOpen:
                              index === preValue.index
                                ? !preValue.isOpen
                                : true,
                          }));
                        }}
                      />
                    ) : (
                      <img
                        src={Down}
                        alt="^"
                        className="img"
                        onClick={() => {
                          setShowDetails((preValue) => ({
                            ...preValue,
                            index,
                            isOpen:
                              index === preValue.index
                                ? !preValue.isOpen
                                : true,
                          }));
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                {showDetails.index === index &&
                  showDetails.isOpen &&
                  moreDetails(data.more)}
              </div>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default TimeLogs;
