import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { invokeApi } from "./api/index";
import { API_URL_DEVICE, ADD_CARD, CREATE_SUBSCRIPTION } from "./api/constant";
import AddCardImage from "../resources/images/add-card.png";
import { toast } from "react-toastify";
import {
  Elements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
} from "@stripe/react-stripe-js";
const stripePromise = loadStripe(
  "pk_test_51KBconKrhx0TkMplfjMD76lNchI0zLLScYqioLUdGzBDMuuM0hddEYtAiKwj1m1aeV1mc58nnz7dNOC4SbXQpJzo00u9rUvVrq"
);
const useStyles = makeStyles((theme) => ({
  inputField: {
    // maxWidth: "60%",
    backgroundColor: "#E3ECF2",
    padding: 6,
    // margin: "0 auto",
    borderRadius: 20,
    paddingLeft: 20,
  },
  label: {
    color: "rgb(4, 85, 101)",
    marginBottom: 10,
    paddingLeft: 10,
    marginTop: "5px",
  },
  container: {
    border: "1px solid white",
    padding: 20,
    margin: "30px 40px 30px 40px",
    backgroundColor: "white",
    borderRadius: 20,
  },
  buttonContainer: {
    display: "flex",
    marginBottom: 50,
    minWidth: "30%",
    justifyContent: "space-around",
  },
}));

const AddCard = (props) => {
  const classes = useStyles();
  const history = useNavigate();

  useEffect(() => {
    props.headerName("Card");
  }, []);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [userDetails, setUserDetails] = useState({
      firstName: "",
      lastName: "",
      country: "",
      state: "",
      address1: "",
      address2: "",
      zipCode: "",
      city: "",
      phoneNumber: "",
    });

    const handleSubmit = async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      if (
        userDetails.firstName !== "" &&
        userDetails.address1 !== "" &&
        userDetails.state !== "" &&
        userDetails.zipCode !== "" &&
        userDetails.city !== "" &&
        userDetails.country !== ""
      ) {
        const cardNumberElement = elements.getElement(CardNumberElement);
        const res = await stripe.createToken(cardNumberElement, {
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          address_line1: `${userDetails.address1}`,
          address_line2: `${userDetails.address2}`,
          address_city: `${userDetails.city}`,
          address_state: `${userDetails.state}`,
          address_zip: `${userDetails.zipCode}`,
          address_country: `${userDetails.country}`,
        });
        if (!res.error) {
          const Cardtoken = res.token.id;
          invokeApi("POST", `${API_URL_DEVICE}${ADD_CARD}`, {
            tok: Cardtoken,
          })
            .then((response) => {
              if (response.message) {
                toast.success(`${response.message}`, { autoClose: 2000 });
                elements.getElement(CardNumberElement).clear();
                elements.getElement(CardExpiryElement).clear();
                elements.getElement(CardCvcElement).clear();
                setUserDetails({
                  firstName: "",
                  lastName: "",
                  country: "",
                  state: "",
                  address1: "",
                  address2: "",
                  zipCode: "",
                  city: "",
                });
              }
              history("/setting");
            })
            .catch((error) => {
              console.log(error.message);
            });
        } else {
          toast.error(res.error.message, { autoClose: 2000 });
        }
      } else {
        console.log("Incomplete User or Address Details");
      }
    };
    return (
      <div
        style={{
          maxHeight: "90vh",
          // padding: "20px 20px 20px 7%",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className={classes.container}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <h2
                style={{
                  color: "rgb(4, 85, 101)",
                  paddingLeft: 10,
                  marginTop: "5px",
                }}
              >
                Card Details
              </h2>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid md={12} xs={12}>
                  <Grid item md={10} xs={12}>
                    <div>
                      <h4 className={classes.label}>Card Number</h4>
                      <div
                        style={{
                          maxWidth: "90%",
                          backgroundColor: "#E3ECF2",
                          borderRadius: 20,
                          paddingLeft: 20,
                          padding: 13,
                          marginBottom: 10,
                        }}
                      >
                        <CardNumberElement />
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={10} xs={12} style={{ display: "flex" }}>
                    {" "}
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>CVC</h4>
                        <div
                          style={{
                            maxWidth: "80%",
                            backgroundColor: "#E3ECF2",
                            borderRadius: 20,
                            paddingLeft: 20,
                            padding: 13,
                            marginBottom: 10,
                          }}
                        >
                          <CardCvcElement />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>Exp.</h4>
                        <div
                          style={{
                            maxWidth: "80%",
                            backgroundColor: "#E3ECF2",
                            borderRadius: 20,
                            paddingLeft: 20,
                            padding: 13,
                            marginBottom: 10,
                          }}
                        >
                          <CardExpiryElement />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item md={10} xs={12} style={{ display: "flex" }}>
                    {" "}
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4 className={classes.label}>Name on Card</h4>
                        <TextField
                          name="name"
                          onChange={(e) => {
                            setUserDetails((prevVal) => ({
                              ...prevVal,
                              firstName: e.target.value,
                            }));
                          }}
                          className={classes.inputField}
                          value={userDetails.firstName}
                          color="primary"
                          placeholder="First Name"
                          variant="standard"
                          style={{ width: "80%", backgroundColor: "none" }}
                          required
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <div>
                        <h4
                          className={classes.label}
                          style={{ color: "white" }}
                        >
                          .
                        </h4>
                        <TextField
                          name="name"
                          onChange={(e) => {
                            setUserDetails((prevVal) => ({
                              ...prevVal,
                              lastName: e.target.value,
                            }));
                          }}
                          className={classes.inputField}
                          color="primary"
                          value={userDetails.lastName}
                          placeholder="Last Name"
                          variant="standard"
                          style={{ width: "80%" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid md={6} xs={12}>
              <img
                src={AddCardImage}
                alt="Add Card"
                style={{ width: 450, height: 305, marginTop: 10 }}
              />
            </Grid>
          </div>
        </div>
        <div className={classes.container}>
          <div>
            <h2
              style={{
                color: "rgb(4, 85, 101)",
                paddingLeft: 10,
                marginTop: "5px",
              }}
            >
              Address
            </h2>
            <Grid container spacing={1}>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>Line 1</h4>
                  <TextField
                    onChange={(e) => {
                      setUserDetails((prevVal) => ({
                        ...prevVal,
                        address1: e.target.value,
                      }));
                    }}
                    className={classes.inputField}
                    color="primary"
                    placeholder="Line 1"
                    required
                    value={userDetails.address1}
                    variant="standard"
                    style={{ width: "75%" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <h4 className={classes.label}>Line 2</h4>
                  <TextField
                    onChange={(e) => {
                      setUserDetails((prevVal) => ({
                        ...prevVal,
                        address2: e.target.value,
                      }));
                    }}
                    className={classes.inputField}
                    color="primary"
                    placeholder="Line 2"
                    value={userDetails.address2}
                    variant="standard"
                    style={{ width: "75%" }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <div>
                    <h4 className={classes.label}>State</h4>
                    <TextField
                      name="name"
                      onChange={(e) => {
                        setUserDetails((prevVal) => ({
                          ...prevVal,
                          state: e.target.value,
                        }));
                      }}
                      className={classes.inputField}
                      color="primary"
                      required
                      value={userDetails.state}
                      placeholder="State"
                      variant="standard"
                      style={{ width: "75%" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12} style={{ display: "flex" }}>
                <Grid item md={4} xs={12}>
                  <div>
                    <h4 className={classes.label}>Zip Code</h4>
                    <TextField
                      name="name"
                      onChange={(e) => {
                        setUserDetails((prevVal) => ({
                          ...prevVal,
                          zipCode: e.target.value,
                        }));
                      }}
                      className={classes.inputField}
                      color="primary"
                      placeholder="Zip Code"
                      required
                      value={userDetails.zipCode}
                      variant="standard"
                      style={{ width: "75%" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div>
                    <h4 className={classes.label}>City</h4>
                    <TextField
                      name="name"
                      onChange={(e) => {
                        setUserDetails((prevVal) => ({
                          ...prevVal,
                          city: e.target.value,
                        }));
                      }}
                      className={classes.inputField}
                      color="primary"
                      placeholder="City"
                      required
                      value={userDetails.city}
                      variant="standard"
                      style={{ width: "75%" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={4} xs={12}>
                  <div>
                    <h4 className={classes.label}>Country</h4>
                    <TextField
                      name="name"
                      onChange={(e) => {
                        setUserDetails((prevVal) => ({
                          ...prevVal,
                          country: e.target.value,
                        }));
                      }}
                      className={classes.inputField}
                      color="primary"
                      value={userDetails.country}
                      placeholder="Country"
                      required
                      variant="standard"
                      style={{ width: "75%" }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <Grid
          item
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgba(8, 202, 207, 0.87) ",
                borderRadius: 20,
                paddingRight: 60,
                paddingLeft: 60,
                color: "white",
              }}
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#06415d ",
                borderRadius: 20,
                paddingRight: 60,
                paddingLeft: 60,
                color: "white",
              }}
              onClick={() => {
                history("/setting");
              }}
            >
              Back
            </Button>
          </div>
        </Grid>
      </div>
    );
  };

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  );
};

export default AddCard;
