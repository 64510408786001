import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@material-ui/core";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { invokeApi, invokeGraphApi } from "./api/index";
import {
  API_URL_DEVICE,
  GROUP_DROPDOWN,
  DEVICE,
  INVOKE,
  TYPE_SERVICE,
  REVOKE,
  TYPE_PROCESS,
  CHUNK_TYPE_PROCESS,
  PING,
} from "./api/constant";
import { toast } from "react-toastify";
import Select from "../components/common/Select";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

const columns = [
  { id: "pid", label: "PID" },
  { id: "owner", label: "Owner" },
  { id: "command", label: "Command" },
  { id: "cpuUsage", label: "CPU Usage (%)" },
  { id: "memoryUsage", label: "Memory Usage (%)" },
  { id: "kill", label: "Kill" },
];
const servicesColumns=[
  { id: "name", label: "Name" },
  { id: "status", label: "Status" },
  { id: "serviceState", label: "Service State" },
  { id: "action", label: "Action" },
]
const Process = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [processList, setProcessList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [active, setActive] = useState(0);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(null);
  const [resp, setResp] = useState(null);
  const [loader, setLoader] = useState(false);
  const[serviceList,setServiceList]=useState([
   
])
  let deviceFilterLet = deviceFilter;
  let revokeId = null;
  const KillProcess = (pid) => {
    invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
      type: "KILL_PROCESS",
      deviceId: deviceFilter,
      pid: pid,
      reset: false,
    })
      .then((response) => {
        toast.success("Process Terminated successfully", { autoClose: 2000 });
      })
      .catch((err) => {
        console.log("Process Terminated Failed");
      });
  };

  const ActionBtn = (serviceName,action) => {
    invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
      type: "CHANGE_SYSTEMCTL_UNIT_STATUS",
      deviceId: deviceFilter,
 serviceName,
 action,
      reset: false,
    })
      .then((response) => {   
        if(response.message){
          toast.error(response.message,{autoClose:2000})
        }else{
          toast.success(" Terminated successfully", { autoClose: 2000 }); 
        }
       
      })
      .catch((err) => {
        console.log(" Termination Failed");
      });
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  //setting Service list data


  useEffect(() => {
    if(active==1){
    setDeviceFilter(device);
    setGroupFilter(group);
    const interval = setInterval(() => {
      if (revokeId !== null) {
        invokeGraphApi("POST", `${API_URL_DEVICE}${PING}${revokeId}`)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });
      }
    }, 5000);
    if (device!= null) {
      invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
        type: TYPE_SERVICE,
        deviceId: device, //"33d1edc5-a76f-4907-b6b9-738a58b09ea4",
        reset: false,
      }).then((res) => {
        revokeId = res.id;
      });
      let channel2 = props.pusher.subscribe(device);
      bindWithChunking(channel2, "CHUNKED_LIVE_SYSTEMCTL_LIST", function (res) {
        // console.log(res)
        // debugger;
        let data = res.systemCtlList
        .map((iter, pos) => {
            return serviceData(
              iter.unit,
              capitalizeFirstLetter(iter.active),
              capitalizeFirstLetter(iter.sub),
              "Action"
            );
        
        })
        setServiceList(data)
   

      });
      
    }
    return () => {
      clearInterval(interval);
      if (revokeId != null) {
  setServiceList([])
        invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
          type: TYPE_SERVICE,
          deviceId: device,
          // inf: "en0",
          reset: false,
        });
      }
    };
  }
  }, [group, device,active]);
const serviceData=(name,status,state)=>{
  let serviceState=<p style={{color:state==="Running"?"green":state==="Failed"?"red":"brown",margin:"0px"}}>{state}</p>
  let action =<div>
    
    <Button variant="contained" onClick={()=>ActionBtn(name,"start")} size="small" style={{backgroundColor:status==="Inactive"?"#cdd1cf":"green",borderRadius:"20px",color:"white",marginRight:"1em",paddingRight:20,paddingLeft:20}}
    disabled={status==="Inactive"&&true}
    >Start</Button>
    <Button variant="contained" onClick={()=>ActionBtn(name,"stop")} size="small" style={{backgroundColor:status==="Inactive"?"#cdd1cf":"red",borderRadius:"20px",color:"white",paddingRight:20,paddingLeft:20}}   disabled={status==="Inactive"}>Stop</Button>

  </div>
  return{
    name,status,serviceState,action
  }
}

  const createData = (
    id,
    pid,
    owner,
    command,
    cpuUsage,
    memoryUsage,
    killButton
  ) => {
    let kill = (
      <div>
        <Button
          variant="contained"
          style={{
            textTransform: "none",
            backgroundColor: "#f9d02fad",
            color: "black",
            borderRadius: "10px",
            height: "25px",
          }}
          onClick={() => {
            KillProcess(pid);
          }}
        >
          {killButton}
        </Button>
      </div>
    );
    return {
      id,
      pid,
      owner,
      command,
      cpuUsage,
      memoryUsage: parseFloat(memoryUsage).toFixed(2),
      kill,
    };
  };

  const groupChange = (e) => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE}` + e)
      .then((res) => {
        let devicedata = [];
        devicedata = res.map((iter) => {
          return { value: iter.id, name: iter.deviceName };
        });
        setDevicesList(devicedata);
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });
  };

  const groupUpdate = () => {
    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  };

  const bindWithChunking = (channel, event, callback) => {
    let events = {};
    channel.bind(event, (data) => {
      if (!events.hasOwnProperty(data.id)) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }
      let ev = events[data.id];
      ev.chunks[data.index] = data.chunk;
      if (data.final) ev.receivedFinal = true;
      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        callback(JSON.parse(ev.chunks.join("")));
        delete events[data.id];
      }
    });
  };

  useEffect(() => {
    props.headerName("Processes & Services");
    groupUpdate();
  }, []);
  

  useEffect(() => {
    if(active==0){
      setDeviceFilter(device);
      setGroupFilter(group);
      const interval = setInterval(() => {
        if (revokeId !== null) {
          invokeGraphApi("POST", `${API_URL_DEVICE}${PING}${revokeId}`)
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            });
        }
      }, 5000);
      if (device != null) {
        invokeApi("POST", `${API_URL_DEVICE}${INVOKE}`, {
          type: TYPE_PROCESS,
          deviceId: device, //"33d1edc5-a76f-4907-b6b9-738a58b09ea4",
          reset: false,
        }).then((res) => {
          revokeId = res.id;
        });
        let channel = props.pusher.subscribe(device); // "33d1edc5-a76f-4907-b6b9-738a58b09ea4"  );
        bindWithChunking(channel, CHUNK_TYPE_PROCESS, function (res) {
          setResp(res);
        });
      }
  
      return () => {
        clearInterval(interval);
        revoke();
      };
    }
   
  }, [group, device,active]);

  useEffect(() => {
    if (groupFilter != null) {
      groupChange(groupFilter);
    }
    if (groupFilter !== null && deviceFilter !== null) {
      setLoader(true);
    }
  }, [groupFilter]);

  const revoke = () => {
    if (revokeId != null) {
      setProcessList([]);

      invokeApi("POST", `${API_URL_DEVICE}${REVOKE}` + revokeId, {
        type: TYPE_PROCESS,
        deviceId: deviceFilterLet,
        // inf: "en0",
        reset: false,
      });
    }
  };

  useEffect(() => {
    if (
      resp != null &&
      resp != "" &&
      groupFilter !== null &&
      deviceFilter !== null
    ) {
      let data = resp?.processes.map((iter, pos) => {
        return createData(
          pos,
          iter.p,
          iter.c,
          iter.o,
          iter.cu,
          iter.mu,
          "Kill"
        );
      });
      setProcessList(data);
      setLoader(false);
    }
  }, [resp]);

  const activeTab = () => {
    if (active == 0) {
      return  <div style={{ padding: "20px" }}>
      <div style={{ color: "#045565" }}>Processes</div>
      <span
        style={{
          fontSize: "10px",
          color: "#808080a8",
          position: "relative",
          top: "-5px",
        }}
      >
        This list gives an overview over currently running system processes
        and their status.
      </span>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "70vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="head">
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: 900 }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {processList.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => {
                      setModal({ ...modal, open: true, details: row });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {loader && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div> 
    
    } else {
      return (
        <div style={{ padding: " 3em 2em" }}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: "transparent !important",
            boxShadow: "none",
          }}
        >
          <TableContainer className="table-body">
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                marginTop: "-10px",
              }}
            >
              <TableHead>
                <TableRow key="head">
                  {servicesColumns.map((col) => (
                    <TableCell
                      key={col.id}
                      style={{
                        color: "#085B74 ",
                        fontWeight: "bold",
                        alignItems: "center",
                      }}
                      align="center"
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {serviceList
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {servicesColumns.map((col) => {
                          const value = row[col.id];
                     
                          return (
                            <TableCell
                              key={col.id}
                              align="center"
                              style={{
                                color: "#085B74",
                                backgroundColor: "white",
                              }}
                            >
                              {col.format && typeof value === "number"
                                ? col.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      );
    }
  };

  return (
    <div>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={resp == null}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
     <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Devices
        </div>
        <div>
          <Select
            list={groupList}
            value={groupFilter}
            name={"Group"}
            onChange={(e) => {
              if (e == null) {
                setDeviceFilter(null);
                setDevicesList([]);
                setGroupFilter(null);
              } else {
                setGroupFilter(e);
              }
            }}
          />
        </div>
        <div>
          <Select
            list={devicesList}
            name={"Device"}
            value={deviceFilter}
            onChange={(e) => {
              setDeviceFilter(e);
            }}
          />
        </div>
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              revoke();
              dispatch(deviceFun(deviceFilter));
              dispatch(groupFun(groupFilter));
              if (groupFilter !== null && deviceFilter !== null) {
                setLoader(true);
              }
              // setFilter(Math.random());
            }}
          >
            Apply
          </Button>
        </div>
      </div>
   
      <div>
      <div className="devicesGroup">
          <div
            style={{
              width: "fit-content",
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "15px",
              position: "relative",
              top: "10px",
              height: "20px",
              left: "20px",
            }}
          >
            <span
              onClick={() => {
                setActive(0);
              }}
              style={{
                backgroundColor: active === 0 ? "#08cacfde" : "white",
                padding: "5px 15px 5px 15px",
                borderRadius: "20px",
                color: active === 0 ? "white" : "black",
                cursor: "pointer",
              }}
            >
              Processes
            </span>
            <span
              onClick={() => {
                setActive(1);
              }}
              style={{
                backgroundColor: active === 1 ? "#08cacfde" : "white",
                padding: "5px 15px 5px 15px",
                borderRadius: "20px",
                color: active === 1 ? "white" : "black",
                cursor: "pointer",
              }}
            >
            Services
            </span>
          </div>
        </div>
      </div>
      {activeTab()}
    </div>
  );
};

export default Process;
