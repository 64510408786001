import React, { useState, useEffect } from "react";
import "./Logs/index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { invokeApi } from "./api";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import RefreshIcon from "@mui/icons-material/Refresh";
import Logs from "../resources/images/logs.png";
import Download from "../resources/images/download.png";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Error from "../resources/images/error.png";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { API_URL_DEVICE, ROLLOUTS, ROLLOUTS_DETAILS } from "./api/constant";
const columns = [
  { id: "deviceName", label: "Device Name" },
  { id: "controllerId", label: "Controller ID" },
  { id: "status", label: "Detail Status" },
  { id: "logs", label: "Update Logs" },
  { id: "action", label: "Action" },
];
const RollOutDetails = (props) => {
  const { rollId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  const [rollOutDetails, setRollOutDetails] = useState([]);
  const [rolloutPage, setRolloutPage] = useState(0);
  const [rolloutBuffer, setRolloutBuffer] = useState([]);
  const [rolloutRefresh, setRolloutRefresh] = useState(0);
  const [rolloutTotalList, setRolloutTotalList] = useState(0);
  const [pusherData, setPusherData] = useState(null);
  const history = useNavigate();
  useEffect(() => {
    props.headerName("Rollout Details");
  }, []);

  useEffect(() => {
    if (rollOutDetails.length && pusherData !== null) {
      setRollOutDetails((prevState) => {
        let newData = prevState.filter((ele) => ele.id !== pusherData.id);
        newData = [
          ...newData,
          createData(
            pusherData.id,
            pusherData.device.id,
            pusherData.device.deviceName,
            pusherData.device.controllerId,
            pusherData.isApplied,
            pusherData.isDownloaded,
            pusherData.isExtracted,
            pusherData.status
          ),
        ];
        return newData;
      });
    }
  }, [pusherData]);

  useEffect(() => {
    const id = user.parent === null ? user.id : user.parent;
    let channel = props.pusher.subscribe(id);
    channel.bind("ROLLOUT_DEVICE_STATUS_UPDATE", (data) => {
      setPusherData(data.deviceState);
    });
    return () => {
      props.pusher.unsubscribe(id);
    };
  }, []);

  useEffect(() => {
    fetchDetails(rolloutPage + 1);
  }, [rolloutRefresh]);
  //get rollout details
  const fetchDetails = (pg) => {
    let params = { page: pg ,sortBy:'-createdAt'};
    invokeApi(
      "GET",
      `${API_URL_DEVICE}${ROLLOUTS_DETAILS}/${rollId}`,
      null,
      params
    )
      .then((res) => {
        const data = res.results.map((iter) => {
          return createData(
            iter.id,
            iter.device.id,
            iter.device.deviceName,
            iter.device.controllerId,
            iter.isApplied,
            iter.isDownloaded,
            iter.isExtracted,
            iter.status
          );
        });
        let array = rolloutBuffer;
        array.push(pg);
        setRolloutBuffer(array);
        setRolloutTotalList(res.totalResults);
        if (pg == 1) {
          setRollOutDetails(data);
        } else {
          setRollOutDetails([...rollOutDetails, ...data]);
        }
      })
      .catch((err) => {
        toast.error("failed to fetch", { autoClose: 2000 });
      });
  };
  //play rollout api call
  const playRollout = (id) => {
    console.log(id);
  };
  const createData = (
    id,
    deviceId,
    deviceName,
    controllerId,
    isApplied,
    isDownloaded,
    isExtracted,
    actionStatus
  ) => {
    let action = (
      <div>
        <RefreshIcon
          fontSize="large"
          style={{
            color: "#0FB9C8",
            cursor: "pointer",
          }}
          onClick={() => {
            playRollout(id);
          }}
        />
      </div>
    );
    let logs = (
      <img
        src={Logs}
        alt="logs"
        style={{ cursor: "pointer" }}
        onClick={() => {
          history(`/rollout/logs/${deviceId}/${rollId}`);
        }}
      />
    );
    let status = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            width: "80%",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            {isDownloaded === true && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Download} alt="completed" />
                Download
              </div>
            )}
            {isDownloaded === false && (
              <div className="rolloutDetailsStatusDiv">
                <div>-</div>
                Download
              </div>
            )}
            {isDownloaded === null && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Error} alt="failed" />
                Download
              </div>
            )}
          </div>
          <div>
            {isExtracted === true && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Download} alt="completed" />
                Extract
              </div>
            )}
            {isExtracted === false && (
              <div className="rolloutDetailsStatusDiv">
                <div>-</div>Extract
              </div>
            )}
            {isExtracted === null && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Error} alt="failed" />
                Extract
              </div>
            )}
          </div>
          <div>
            {isApplied === true && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Download} alt="completed" />
                Apply
              </div>
            )}
            {isApplied === false && (
              <div className="rolloutDetailsStatusDiv">
                <div>-</div>Apply
              </div>
            )}
            {isApplied === null && (
              <div className="rolloutDetailsStatusDiv">
                <img src={Error} alt="failed" />
                Apply
              </div>
            )}
          </div>
        </div>
      </div>
    );
    return {
      id,
      deviceName,
      controllerId,
      status,
      logs,
      action,
    };
  };
  return (
    <div>
      <div style={{ padding: " 3em 2em" }}>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: "transparent !important",
            boxShadow: "none",
          }}
        >
          <TableContainer className="table-body">
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 10px",
                marginTop: "-10px",
              }}
            >
              <TableHead>
                <TableRow key="head">
                  {columns.map((col) => (
                    <TableCell
                      key={col.id}
                      style={{
                        color: "#085B74 ",
                        fontWeight: "bold",
                        alignItems: "center",
                      }}
                      align="center"
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rollOutDetails
                  .slice(rolloutPage * 10, rolloutPage * 10 + 10)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((col) => {
                          const value = row[col.id];
                          return (
                            <TableCell
                              key={col.id}
                              align="center"
                              style={{
                                color: "#085B74",
                                backgroundColor: "white",
                              }}
                            >
                              {col.format && typeof value === "number"
                                ? col.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            style={{ backgroundColor: "white" }}
            component="div"
            count={rolloutTotalList}
            rowsPerPage={10}
            page={rolloutPage}
            onPageChange={(event, newPage) => {
              setRolloutPage(newPage);
              setRolloutRefresh(Math.random());
            }}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RollOutDetails;
