import * as React from "react";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ArticleIcon from "@mui/icons-material/Article";
import DvrIcon from "@mui/icons-material/Dvr";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Modal from "@mui/material/Modal";
import { TextField, Button } from "@material-ui/core";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { invokeApi, plainApi } from "./api/index";
import TimeAgo from "react-timeago";
import offline from "../resources/icons/offline.png";
import fleetIcon from "../resources/icons/rapid-fleet.png";
import online from "../resources/icons/online.png";
import {
  API_URL_DEVICE,
  DASHBOARD_DEVICES,
  DASHBOARD_FLEET,
  DEVICE_DROPDOWN,
  GROUP_DROPDOWN,
  NOTIFICATIONS,
  API_KEY,
} from "./api/constant";
import { toast } from "react-toastify";
import moment from "moment";
import Chart from "react-apexcharts";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Select from "../components/common/Select";
import totalDevice from "../resources/icons/total-device.png";
import { useSelector, useDispatch } from "react-redux";
import { deviceFun, groupFun } from "../components/redux/reducer";

const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap defaultZoom={8} defaultCenter={{ lat: -34.397, lng: 150.644 }}>
      {props.isMarkerShown && (
        <Marker position={{ lat: -34.397, lng: 150.644 }} />
      )}
    </GoogleMap>
  ))
);

const Dashboard = (props) => {
  const device = useSelector((state) => state.filter.device);
  const group = useSelector((state) => state.filter.group);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});
  const [devices, setDevices] = useState([]);
  const [fleet, setFleet] = useState({});
  const [devicesList, setDevicesList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [groupFilter, setGroupFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [filter, setFilter] = useState(0);
  const [hasMore, setHasMore] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(null);
  const observer = React.useRef();
  const [refresh, setRefresh] = useState(0);
  const [pages, setPages] = React.useState(1);
  const [pageArray, setPageArray] = React.useState([]);

  const getNotification = (pg) => {
    let params = group ? { groupId: group, page: pg } : { page: pg };
    if (!pageArray.includes(pg)) {
      plainApi("GET", `${API_URL_DEVICE}${NOTIFICATIONS}`, null, params)
        .then((response) => {
          if (response) {
            setTotalPages(response.totalPages + 1);
            let array = [...pageArray];
            array.push(pg);
            setPageArray(array);
            if (pages == 1) {
              setNotifications(response.results);
            } else {
              setNotifications([...notifications, ...response.results]);
            }
          } else {
            console.log("Fetching notification failed");
          }
        })
        .catch((err) => {
          console.log("Fetching notification failed");
        });
    }
  };

  const lastItemRef = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPages((pages) => pages + 1);
          setRefresh(Math.random());
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  useEffect(() => {
    if (totalPages == null) {
      getNotification(pages);
    } else {
      if (pages < totalPages) {
        setHasMore(true);
        getNotification(pages);
      } else {
        setHasMore(false);
      }
    }
  }, [refresh]);

  useEffect(() => {
    setGroupFilter(group);
    let params = group ? "?groupId=" + group : "";
    invokeApi("GET", `${API_URL_DEVICE}${DASHBOARD_FLEET}` + params)
      .then((response) => {
        if (response) {
          setFleet(response);
        }
      })
      .catch((err) => {
        console.log("Fetching Fleet details failed", err);
      });

    invokeApi("GET", `${API_URL_DEVICE}${DASHBOARD_DEVICES}` + params)
      .then((response) => {
        if (response) {
          let data = new Array(12).fill(0);
          response.forEach((iter) => {
            data[iter.month - 1] = iter.count;
          });
          setDevices(data);
        }
      })
      .catch((err) => {
        console.log("Fetching Device details failed", err);
      });
  }, [group]);

  useEffect(() => {
    props.headerName("Dashboard");
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.results.map((iter) => {
            return { value: iter.id, name: iter.deviceName };
          });
          setDevicesList(data);
        } else {
          console.log("Fetching Device list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching Device list failed");
      });

    invokeApi("GET", `${API_URL_DEVICE}${GROUP_DROPDOWN}`)
      .then((response) => {
        if (response) {
          let data = response.map((iter) => {
            return { value: iter.id, name: iter.name };
          });
          setGroupList(data);
        } else {
          console.log("Fetching group list failed");
        }
      })
      .catch((err) => {
        console.log("Fetching group list failed");
      });
  }, []);

  const notificationTypeFun = (val) => {
    switch (val) {
      default:
        return "#0c53f36b";
    }
  };

  return (
    <div >
      <div
        style={{
          backgroundColor: "#effafb",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            margin: "10px",
            fontSize: ".8rem",
            paddingLeft: "30px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Filter Devices
        </div>
        <div>
          <Select
            list={groupList}
            name={"Group"}
            onChange={(e) => {
              setGroupFilter(e);
            }}
            value={groupFilter}
          />
        </div>
        <div
          style={{
            fotntWeight: "bold",
            marginRight: "20px",
            width: "100px",
            marginTop: "-5px",
            margin: "10px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              height: "25px",
            }}
            onClick={() => {
              dispatch(groupFun(groupFilter));
            }}
          >
            Apply
          </Button>
        </div>
      </div>
      <div  className="dashboardBody">
        <Grid
          container
          spacing={2}
          style={{
            margin: "0px",
            width: "100%",
          }}
        >
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  padding: "20px 20px 10px 30px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    padding: "5px 0px 20px 0px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      paddingTop: "5px",
                      height: "25%",
                      paddingLeft: 18,
                    }}
                  >
                    Device Count
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingTop: "0px", width: "90%" }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to top right, #02955f , white 150%)",
                        borderRadius: "20px",
                        padding: "15px",
                        marginTop: "-16px",
                        color: "white",
                        width: "80%",
                        margin: "5px 0px 5px 0px",
                        display: "flex",
                      }}
                    >
                      <div>
                        <img
                          src={online}
                          alt="Online"
                          style={{
                            width: "40px",
                            height: "40px",
                            position: "relative",
                            top: "2px",
                          }}
                        />
                      </div>
                      <div style={{ margin: "10px" }}>Online</div>
                      <div style={{ margin: "10px", marginLeft: "auto" }}>
                        <span
                          style={{
                            backgroundColor: "#7bdcb7",
                            padding: "10px 15px",
                            borderRadius: "15px",
                            boxShadow: "white 1px 1px",
                            fontSize: "20px",
                          }}
                        >
                          {fleet?.online}
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingTop: "0px", width: "90%" }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to top right, #ce2525 , white 150%)",
                        borderRadius: "20px",
                        padding: "15px",
                        marginTop: "-16px",
                        color: "white",
                        width: "80%",
                        margin: "5px 0px 5px 0px",
                        display: "flex",
                      }}
                    >
                      <div>
                        {/* <ReceiptLongIcon style={{ fontSize: "40px" }} /> */}
                        <img
                          src={offline}
                          alt="Offline"
                          style={{
                            width: "40px",
                            height: "40px",
                            position: "relative",
                            top: "5px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          margin: "10px",
                        }}
                      >
                        Offline
                      </div>
                      <div style={{ margin: "10px", marginLeft: "auto" }}>
                        <span
                          style={{
                            backgroundColor: "#ed5f7a",
                            padding: "10px 15px",
                            borderRadius: "15px",
                            boxShadow: "white 1px 1px",
                            fontSize: "20px",
                          }}
                        >
                          {fleet?.offline}
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ paddingTop: "0px", width: "90%" }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(to top right, #0477ba , white 150%)",
                        borderRadius: "20px",
                        padding: "15px",
                        marginTop: "-16px",
                        color: "white",
                        width: "80%",
                        margin: "5px 0px 5px 0px",
                        display: "flex",
                      }}
                    >
                      <div>
                        {/* <DvrIcon style={{ fontSize: "40px" }} /> */}
                        <img
                          src={totalDevice}
                          alt="Fleet"
                          style={{
                            width: "40px",
                            height: "40px",
                            position: "relative",
                            top: "2px",
                          }}
                        />
                      </div>
                      <div style={{ margin: "10px" }}>Total</div>
                      <div style={{ margin: "10px", marginLeft: "auto" }}>
                        <span
                          style={{
                            backgroundColor: "#3893c8",
                            padding: "10px 15px",
                            boxShadow: "white 1px 1px",
                            borderRadius: "15px",
                            fontSize: "20px",
                          }}
                        >
                          {fleet?.total}
                        </span>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "25px 20px 10px 30px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    height: "26vh",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{ paddingTop: 12, paddingLeft: 18 }}
                  >
                    Device Registration
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "5px" }}>
                    <Chart
                      type="bar"
                      height={"100%"}
                      options={{
                        chart: {
                          toolbar: {
                            show: false,
                          },
                          zoom: {
                            enabled: true,
                          },
                        },
                        plotOptions: {
                          bar: {
                            distributed: true,
                            horizontal: false,
                            colors: {
                              backgroundBarColors: new Array(12).fill("#eee"),
                              backgroundBarOpacity: 1,
                              backgroundBarRadius: 10,
                            },
                          },
                        },
                        colors: ["#0682ce"],
                        dataLabels: {
                          enabled: false,
                        },
                        grid: {
                          show: false,
                        },
                        xaxis: {
                          categories: [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                          ],
                          axisBorder: { show: false },
                          axisTicks: {
                            show: false,
                          },
                        },
                        yaxis: {
                          show: false,
                        },
                        legend: {
                          show: false,
                        },
                      }}
                      series={[
                        {
                          name: "Devices",
                          data: devices,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              padding: "20px 20px 20px 30px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                maxHeight: "100vh",
              }}
            >
              <Grid
                item
                xs={12}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <div style={{ marginBottom: "5px", paddingLeft: 7 }}>
                  Notifications
                </div>
                <div
                  style={{
                    height: "40vh",
                    overflow: "auto",
                    width: "97%",
                  }}
                >
                  {notifications.length > 0 ? (
                    notifications.map((iter, index) => {
                      if (index + 1 === notifications.length && hasMore) {
                        return (
                          <div
                            ref={lastItemRef}
                            style={{
                              textAlign: "center",
                              padding: "10px",
                            }}
                          >
                            Loading...
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "10px",
                              backgroundColor: "#cbcbd530",
                              margin: "5px",
                              width: "90%",
                              padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: notificationTypeFun(
                                  iter.eventType
                                ),
                                borderRadius: "10px",
                                margin: "0px 10px 0px 0px",
                              }}
                            >
                              <NotificationsIcon
                                style={{
                                  fontSize: "25px",
                                  color: "white",
                                  padding: "5px",
                                }}
                              />
                            </div>
                            <div>
                              <div
                                style={{
                                  // height: "25px",
                                  overflow: "hidden",
                                  fontSize: "12px",
                                }}
                              >
                                {JSON.parse(iter.details)?.message}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  top: "5px",
                                  fontSize: "9px",
                                  color: "gray",
                                }}
                              >
                                <TimeAgo
                                  date={moment(iter.updatedAt)
                                    .local()
                                    .valueOf()}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        color: "gray",
                        padding: "100px",
                      }}
                    >
                      No Messages Found
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: "0px 15px 0px" }}>
          <div
            style={{
              height: "28vh",
              backgroundColor: "white",
              borderRadius: "15px",
            }}
          >
            <MyMapComponent
              isMarkerShown
              googleMapURL={
                `https://maps.googleapis.com/maps/api/js?key=` +
                API_KEY +
                `&v=3.exp&libraries=geometry,drawing,places`
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={
                <div style={{ height: `100%`, borderRadius: "15px" }} />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
