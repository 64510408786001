import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core/styles";
import {
  invokeApi,
  checkPermission,
  formatBytes,
} from "../components/api/index";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import {
  API_URL_DEVICE,
  LIST_CARD,
  DELETE_CARD,
  LIST_INVOICES,
  DEVICE_USAGE,
  API_URL_USER,
} from "../components/api/constant";
const useStyles = makeStyles((theme) => ({
  systemLeft: {
    paddingTop: "0px !important",
    height: "35px",
    color: "rgb(167, 169, 169);",
    fontWeight: "bold",
    fontSize: ".8rem",
    display: "flex",
    // justifyContent: "space-between",
  },
  box: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    margin: "10px",
    fontSize: ".8rem",
  },
  label: {
    margin: "6px",
    fontSize: 18,
    color: "rgb(4, 73, 102)",
  },
  value: {
    margin: "6px",
    fontWeight: "lighter",
    fontSize: 18,
    color: "#0a919d",
  },
  inputDiv: {
    borderWidth: "1px",
    backgroundColor: "#dcfaf8",
    padding: "5px 30px 5px 30px",
    marginRight: "20px",
    maxWidth: "100%",
    fontSize: "15px",
    borderRadius: "10px",
    color: "rgb(4, 85, 101)",
  },
  switchBase: {
    color: "white",
    "&$checked": {
      color: "white",
    },
    "&$checked + $track": {
      backgroundColor: "white",
    },
  },
  track: {
    height: "100%",
    minWidth: "100%",
    borderRadius: "7px",
    zIndex: -1,
    position: "relative",
    // left: "-50%",
  },
}));
const Account = (props) => {
  const classes = useStyles();
  const history = useNavigate();
  const [emailStatus, setEmailStatus] = useState(false);
  const [listUserCards, setListUserCards] = useState([]);
  const [listUserInvoices, setListUserInvoices] = useState([]);
  const [delModal, setDelModal] = useState({
    id: null,
    status: false,
  });
  const [subLimit, setSubLimit] = useState({
    devices: {},
    groups: {},
    storage: {},
    users: {},
  });
  const [isAllowed, setIsAllowed] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState(
    "/static/images/avatar/2.jpg"
  );
  const user = JSON.parse(localStorage.getItem("user"))?.user;
  const limits = JSON.parse(localStorage.getItem("user"))?.limits;
  let activeSub = props.activeSubDetails;
  let userDefaultPlan = props.defaultPlan;
  useEffect(() => {
    invokeApi("GET", `${API_URL_DEVICE}${LIST_CARD}`)
      .then((response) => {
        if (!response.message) {
          setListUserCards(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    let allowed = checkPermission(
      "ALLOW_BILLING",
      user?.isAdmin,
      limits?.allowBilling
    );
    setIsAllowed(allowed);
  }, []);
  useEffect(() => {
    invokeApi("GET", `${API_URL_DEVICE}${LIST_INVOICES}`)
      .then((response) => {
        if (!response.message) {
          setListUserInvoices(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    invokeApi("GET", `${API_URL_DEVICE}${DEVICE_USAGE}`)
      .then((res) => {
        setSubLimit((prev) => ({
          ...prev,
          devices: res.devices,
          groups: res.groups,
          storage: {
            ...res.storage,
            allowed: formatBytes(res.storage.allowed)[0],
            unit: res.storage.unit,
          },
          users: res.users,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteCardHandler = (cardId) => {
    invokeApi("DELETE", `${API_URL_DEVICE}${DELETE_CARD}`, {
      cardId: cardId,
    })
      .then((response) => {
        setListUserCards(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteCard = () => {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#075a6a",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            padding: "20px",
            color: "#c1d8dd",
          }}
        >
          <span style={{ paddingLeft: "40%" }}>Are You Sure ?</span>
          <span
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
            style={{ float: "right", cursor: "pointer" }}
          >
            X
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0px 20px 0px",
          }}
        >
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#0fcbd0",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              deleteCardHandler(delModal.id);
              setDelModal((prevVal) => ({
                ...prevVal,
                id: null,
                status: false,
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#075a6a",
              color: "white",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={() => {
              setDelModal((prevVal) => ({
                ...prevVal,
                status: false,
                id: null,
              }));
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };
  const deleteFun = () => {
    return (
      <Modal
        disableEnforceFocus={true}
        open={delModal.status === true ? true : false}
        onClose={(e, reason) => {
          if (reason != "backdropClick") {
            setDelModal((prevVal) => ({
              ...prevVal,
              status: false,
              id: null,
            }));
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="createGroup" style={{ p: 4, maxWidth: "550px" }}>
          {deleteCard()}
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <div className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <Avatar
                alt={user?.name?.toUpperCase()}
                src={userProfileImage}
                style={{
                  width: "150px",
                  height: "150px",
                  position: "relative",
                  // top: "10px",
                  fontSize: 130,
                  alignItems: "center",
                  padding: "0 auto",
                  display: "flex",
                  // alignItems: "stretch",
                }}
              />
              <div style={{ marginRight: 80, marginLeft: 80, marginTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <p className={classes.label}>Name:</p>
                  <p
                    className={classes.value}
                    style={{ textTransform: "capitalize" }}
                  >
                    {user?.name}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p className={classes.label}>Email:</p>
                  <p className={classes.value}>{user?.email}</p>
                </div>
                <div style={{ display: "flex" }}>
                  <p className={classes.label}>Designation:</p>
                  <p className={classes.value}>{user?.designation}</p>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <div style={{ display: "flex" }}>
                  <p className={classes.label}>User Type:</p>
                  <p className={classes.value}>{user?.type}</p>
                </div>

                <div style={{ display: "flex" }}>
                  <p className={classes.label}>Region:</p>
                  <p className={classes.value}>{user.region}</p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container md={12} sm={12}>
        <Grid item md={6} xs={12}>
          <div
            className={classes.box}
            style={{ paddingBottom: "50px", height: "60%" }}
          >
            <Grid container spacing={2} md={12} xs={12}>
              <Grid item md={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "30px",
                  }}
                >
                  <div style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}>
                    Subscription Setting
                  </div>
                </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "#70e4e7",
                    border: "none",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={classes.systemLeft}
                style={{ height: "24px", marginTop: "10px" }}
              >
                <div>Subscription Plan</div>
              </Grid>
              <Grid item xs={12} md={12} className={classes.systemLeft}>
                <div
                  className={classes.inputDiv}
                  style={{
                    width: "250px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {activeSub.plan !== ""
                    ? activeSub.plan
                    : userDefaultPlan.name}
                </div>
                {isAllowed ? (
                  <div
                    onClick={() => {
                      history("/upgrade-plan");
                    }}
                    style={{
                      backgroundColor: "#08cacfde",
                      padding: "10px 10px ",
                      borderRadius: "15px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    Upgrade Plan
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#c7c6c3",
                      padding: "10px 10px ",
                      borderRadius: "15px",
                      color: "white",
                    }}
                  >
                    Upgrade Plan
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.box} style={{ paddingBottom: "50px" }}>
            <Grid container spacing={1} md={12} xs={12}>
              <Grid item md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "30px",
                  }}
                >
                  <div style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}>
                    Subscription Limit
                  </div>
                </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "#70e4e7",
                    border: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.systemLeft}>
                <Grid md={6} xs={12}>
                  <h4 style={{ margin: "0px 0px 5px 0px" }}>Devices</h4>
                  <div >
                    <div className={classes.inputDiv}>
                      {subLimit.devices.allowed == -1
                        ? `unlimited`
                        : `${subLimit.devices.cur}/${subLimit.devices.allowed}`}
                    </div>
                  </div>
                </Grid>
                <Grid md={6} xs={12}>
                  <h4 style={{ margin: "0px 0px 5px 0px" }}>Groups</h4>
                  <div>
                    <div className={classes.inputDiv}>
                      {subLimit.groups.allowed == -1
                        ? `unlimited`
                        : `${subLimit.groups.cur}/${subLimit.groups.allowed}`}
                    </div>
                  </div>
                </Grid>
                
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.systemLeft}
                style={{ marginTop: "25px" }}
              >
                 <Grid md={6} xs={12}>
                  <h4 style={{ margin: "0px 0px 5px 0px" }}>Storage</h4>
                  <div>
                    <div className={classes.inputDiv}>
                      {subLimit.storage.allowed == -1
                        ? `unlimited`
                        : `${subLimit.storage.cur}/${subLimit.storage.allowed}`}

                      {` `}
                      {subLimit.storage.unit === "B" ||
                      subLimit.storage.unit === "KB"
                        ? "MB"
                        : subLimit.storage.unit}
                    </div>
                  </div>
               </Grid>
               <Grid md={6} xs={12}>
                  <h4 style={{ margin: "0px 0px 5px 0px" }}>Users</h4>
                  <div>
                    <div className={classes.inputDiv}>
                      {subLimit.users.allowed == -1
                        ? `unlimited`
                        : `${subLimit.users.cur}/${subLimit.users.allowed}`}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {isAllowed && (
        <Grid container md={12} sm={12}>
          <Grid item md={6} sm={12}>
            {" "}
            <div
              className={classes.box}
              style={{
                paddingBottom: "100px",
              }}
            >
              <Grid container spacing={2} md={12} xs={12}>
                <Grid item md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}
                    >
                      Payment Method
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history("/add-card");
                      }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        style={{ color: "#70e4e7", paddingRight: 5 }}
                      />

                      <p style={{ color: "rgb(167, 169, 169)" }}>Add Card</p>
                    </div>
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                      marginBottom: 10,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12} className={classes.systemLeft}>
                  {listUserCards.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "scroll",
                        minHeight: 100,
                        overflowX: "hidden",
                        width: "100%",
                      }}
                    >
                      {listUserCards.map((card) => {
                        return (
                          <div
                            className={classes.inputDiv}
                            style={{
                              width: "85%",
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <p style={{ margin: "0px" }}>
                              {card.brand}-{card.last4} (Expires {card.expMonth}
                              /{card.expYear})
                            </p>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {card.isDefault ? (
                                <p style={{ color: "green", margin: 0 }}>
                                  Active
                                </p>
                              ) : null}
                              <DeleteIcon
                                style={{
                                  color: "rgb(6, 63, 87)",
                                  paddingRight: 10,
                                  cursor: "pointer",
                                  paddingLeft: 10,
                                }}
                                onClick={() =>
                                  setDelModal((prevVal) => ({
                                    ...prevVal,
                                    status: true,
                                    id: card.id,
                                  }))
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <h3
                        style={{
                          color: "rgb(4, 73, 102)",
                          paddingLeft: 10,
                          marginTop: 15,
                          marginBottom: 15,
                        }}
                      >
                        No Card Added
                      </h3>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item md={6} sm={12}>
            <div
              className={classes.box}
              style={{
                paddingBottom: "100px",
              }}
            >
              <Grid container spacing={1} md={12} xs={12}>
                <Grid item md={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "30px",
                    }}
                  >
                    <div
                      style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}
                    >
                      Invoices
                    </div>
                  </div>
                  <hr
                    style={{
                      height: "1px",
                      backgroundColor: "#70e4e7",
                      border: "none",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.systemLeft}>
                  {listUserInvoices.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "scroll",
                        minHeight: 100,
                        overflowX: "hidden",
                        width: "100%",
                      }}
                    >
                      {listUserInvoices.map((invoice) => {
                        return (
                          <div
                            className={classes.inputDiv}
                            style={{
                              width: "85%",
                              marginBottom: 10,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ margin: "0px" }}>
                              {" "}
                              {`$${invoice.amount / 100}  ${new Date(
                                invoice.created * 1000
                              )
                                .toUTCString()
                                .split(" ")
                                .slice(0, 4)
                                .join(" ")}`}
                            </p>
                            <div style={{ display: "flex" }}>
                              <p
                                style={{
                                  paddingLeft: 12,
                                  paddingRight: 15,
                                  margin: "0px",
                                  color:
                                    invoice.status === "paid" ? "green" : "red",
                                }}
                              >
                                {invoice.status}
                              </p>
                              <a
                                href={invoice.pdf}
                                style={{ color: "rgb(4, 85, 101)" }}
                              >
                                {" "}
                                <VisibilityIcon />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <h3
                        style={{
                          color: "rgb(4, 73, 102)",
                          paddingLeft: 10,
                          marginTop: 15,
                          marginBottom: 15,
                        }}
                      >
                        No Invoice available
                      </h3>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}

      <Grid container md={12} sm={12}>
        <Grid item md={6} sm={12}>
          <div className={classes.box} style={{ paddingBottom: "30px" }}>
            <Grid container spacing={2} md={12} xs={12}>
              <Grid item md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "30px",
                  }}
                >
                  <div style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}>
                    Permissions
                  </div>
                </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "#70e4e7",
                    border: "none",
                    marginBottom: 10,
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className={classes.systemLeft}
                style={{ overflowY: "scroll", minHeight: "100px" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {limits.allowBilling && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Billing
                    </p>
                  )}
                  {limits.allowDeviceCtrl && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Device Control
                    </p>
                  )}
                  {limits.allowInvitesAndModifyAccounts && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Invites And Modify Accounts
                    </p>
                  )}
                  {limits.allowMonitorDevice && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Moniter Device
                    </p>
                  )}
                  {limits.allowRegisterDevice && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Register new devices
                    </p>
                  )}
                  {limits.allowRollouts && (
                    <p
                      style={{
                        color: "rgb(4, 73, 102)",
                        margin: "0px 0px 10px 0px",
                        fontSize: "medium",
                      }}
                    >
                      Rollouts
                    </p>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={6} sm={12}>
          {" "}
          <div className={classes.box} style={{ paddingBottom: "100px" }}>
            <Grid container spacing={1} md={12} xs={12}>
              <Grid item md={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "30px",
                  }}
                >
                  <div style={{ fontSize: "1rem", color: "rgb(25, 133, 155)" }}>
                    Device Details
                  </div>
                </div>
                <hr
                  style={{
                    height: "1px",
                    backgroundColor: "#70e4e7",
                    border: "none",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.systemLeft}>
                <div>
                  <div style={{ display: "flex" }}>
                    <p className={classes.label}>Total Groups:</p>
                    <p className={classes.value}>{subLimit.groups.cur}</p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className={classes.label}>Updates:</p>
                    <p className={classes.value}>0</p>
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", marginLeft: 120 }}>
                    <p className={classes.label}>Total device:</p>
                    <p className={classes.value}>{subLimit.devices.cur}</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {deleteFun()}
    </div>
  );
};

export default Account;
